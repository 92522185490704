import React from 'react';
import './css/Commercial.css';

import ShantaiAparment from './assets/image/commercial/shantai_corner.avif'
import ShantaiHomes from './assets/image/building/shantaihomes.avif'
import ShantaiGreen from './assets/image/building/shantaigreen.avif'
import ShantaiClassic from './assets/image/building/shantaiclassic.avif'
import ShantaiHeritage from './assets/image/building/shantaiheritage.avif'
import ShantaiHeights from './assets/image/building/shantaiheight.avif'
import ShantaiLaxmiCorner from './assets/image/building/laxmicorner.avif'
import ElenaHomes from './assets/image/building/eleenahomes.avif'
import RoySir from './assets/image/building/roysir.avif'
import ShantaiResidency from './assets/image/building/shantairesidence.avif'
import DivineHomes from './assets/image/building/divinehomes.avif'
import DivineBless from './assets/image/building/divinebless.avif'
import ShantaiCorner from './assets/image/building/shantaicorner.avif'
import ResidentialBanner from './assets/image/resi-bg.avif'

function ResCompleted() {
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner'>
                <img src={ResidentialBanner} width={'100%'} height={'100%'}/>
                <div className='banner-path'>
                    <h1>Residential Projects</h1>
                    <p><a href='/home'>Home</a> &#8594; <a href='/projects'>Projects</a> &#8594; Completed Projects</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* Total Residential Projects Start */}
            <div className='project-status'>
                <h1>Completed Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                    <a href='/projects/completed/shantai_homes' className='project-img project-card'>
                        <img src={ShantaiHomes} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Homes</h1>
                            <p>Chikhali</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_green' className='project-img project-card'>
                        <img src={ShantaiGreen} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Green</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_classic' className='project-img project-card'>
                        <img src={ShantaiClassic} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Classic</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_heritage' className='project-img project-card'>
                        <img src={ShantaiHeritage} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Heritage</h1>
                            <p>Akurdi</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_heights' className='project-img project-card'>
                        <img src={ShantaiHeights} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Heights</h1>
                            <p>Kharalwadi</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_laxmi_corner' className='project-img project-card'>
                        <img src={ShantaiLaxmiCorner} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Laxmi Corner</h1>
                            <p>Akurdi</p>
                        </div>
                    </a>
                    <a href='/projects/completed/elena_homes' className='project-img project-card'>
                        <img src={ElenaHomes} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Elena Homes</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_residency' className='project-img project-card'>
                        <img src={ShantaiResidency} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Residency</h1>
                            <p>Rahatani</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_apartment' className='project-img project-card'>
                        <img src={ShantaiAparment} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Apartment</h1>
                            <p>Rahatani</p>
                        </div>
                    </a>
                    <a href='/projects/completed/roysir' className='project-img project-card'>
                        <img src={RoySir} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Roy Apartment</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/divine_homes' className='project-img project-card'>
                        <img src={DivineHomes} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Divine Homes</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/divine_bless' className='project-img project-card'>
                        <img src={DivineBless} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Divine Bless</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_corner' className='project-img project-card'>
                        <img src={ShantaiCorner} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Corner</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                </div>
            </div>

            {/* Footer Start */}
            <div className='footer-class'>
                <div class="footer-content grid grid-cols-3">
                    <div class="footer-column footer-about">
                        <h3>About Us</h3>
                        <p><i className="fa fa-info me-3" aria-hidden="true"></i>We bring dreams to life with a focus on innovation, quality, and sustainability.</p>
                    </div>
                    <div class="footer-column">
                        <h3>Contact Us</h3>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Shaantaai Corner, Bhondve Bagh Rd, Ravet, Pune, Maharashtra, India</p>
                        <p><i className="fa fa-envelope me-3"></i>info@vtagroups.com</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+123 456 7890</p>
                    </div>
                    <div class="footer-column">
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i>Facebook</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i>Whatsapp</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i>Instagram</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    &copy; 2024&nbsp;<a>VTA Groups</a>. All rights reserved. Created By &nbsp;<a>Mad Coders</a>.
                </div>
            </div>
            {/* Footer End */}
        </div>
    )
}

export default ResCompleted;
