import React, { useState } from "react";
import {
    Nav,
    NavLogoContainer,
    Logo,
    CompanyName,
    NavLink,
    Bars,
    NavMenu,
    DropdownHover,
    DropdownContent,
} from "./navbarElements";

import LogoImage from '../assets/vta-logo.avif';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };
    return (
        <>
            <Nav>
                <NavLogoContainer>
                    <Logo src={LogoImage} alt="Logo" />
                    <CompanyName>VTA Buildcon</CompanyName>
                </NavLogoContainer>
                <Bars onClick={toggleMenu} />
                <NavMenu isOpen={isOpen}>
                    <NavLink to="/" onClick={closeMenu} >Home</NavLink>
                    <NavLink to="/about" onClick={closeMenu} >About</NavLink>
                    <DropdownHover>
                        <NavLink to="/projects" activeStyle onClick={closeMenu}>Projects</NavLink>
                        <DropdownContent>
                            <NavLink to="/projects/ongoing" activeStyle onClick={closeMenu}>Ongoing</NavLink>
                            <NavLink to="/projects/completed" activeStyle onClick={closeMenu}>Completed</NavLink>
                            <NavLink to="/projects/upcoming" activeStyle onClick={closeMenu}>Upcoming</NavLink>
                        </DropdownContent>
                    </DropdownHover>
                    <NavLink to="/contact" activeStyle onClick={closeMenu}>Contact</NavLink>
                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;