import React from 'react';
import './css/Commercial.css';

import ShantaiClassicPhase2 from './assets/image/building/shantaiclassic.avif'
import ResidentialBanner from './assets/image/resi-bg.avif'

function ResUpcoming() {
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner'>
                <img width={'100%'} height={'100%'} src={ResidentialBanner}/>
                <div className='banner-path'>
                    <h1>Upcoming Projects</h1>
                    <p><a href='/home'>Home</a> &#8594; <a href='/projects'>Projects</a> &#8594; Upcoming Projects</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* Upcoming Projects */}
            <div className='project-status'>
                <h1>Upcoming Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                <a href='/projects/upcoming/shantai_classic_phase_2' className='project-img project-card'>
                        <img width={'100%'} height={'100%'} src={ShantaiClassicPhase2} className='image project-image' />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Classic<br/>Phase 2</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                </div>
            </div>

            {/* Footer Start */}
            <div className='footer-class'>
                <div class="footer-content grid grid-cols-3">
                    <div class="footer-column footer-about">
                        <h3>About Us</h3>
                        <p><i className="fa fa-info me-3" aria-hidden="true"></i>We bring dreams to life with a focus on innovation, quality, and sustainability.</p>
                    </div>
                    <div class="footer-column">
                        <h3>Contact Us</h3>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Shaantaai Corner, Bhondve Bagh Rd, Ravet, Pune, Maharashtra, India</p>
                        <p><i className="fa fa-envelope me-3"></i>info@vtagroups.com</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+123 456 7890</p>
                    </div>
                    <div class="footer-column">
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i>Facebook</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i>Whatsapp</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i>Instagram</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    &copy; 2024&nbsp;<a>VTA Groups</a>. All rights reserved. Created By &nbsp;<a>Mad Coders</a>.
                </div>
            </div>
            {/* Footer End */}
        </div>
    )
}

export default ResUpcoming;
