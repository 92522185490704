import React from 'react';
import './css/Home.css';
import CountUp from 'react-countup';

// AwesomeSlider
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import AboutUsHome from './assets/image/about-us.avif'
import CompletedBuild from './assets/image/completed.avif'
import OngoingBuild from './assets/image/ongoing.avif'
import UpcomingBuild from './assets/image/upcoming.avif';

import Image1 from './assets/image/slider/img1.avif'
import Image2 from './assets/image/slider/img2.avif'
import Image3 from './assets/image/slider/img3.avif'
import Image4 from './assets/image/slider/img4.avif'
import Image5 from './assets/image/slider/img5.avif'
import Image6 from './assets/image/slider/img6.avif'

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Home() {
    return (
        <div>
            {/* Gallery Start */}
            <div className='image-gallery'>
                <AutoplaySlider play={true} cancelOnInteraction={false} interval={3000}>
                    <div className="img-fluid" data-src={Image1} width={'100%'} height={'100%'}>
                        <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0"></h1>
                        </div>
                    </div>
                    <div className="img-fluid" data-src={Image2} width={'100%'} height={'100%'}>
                        <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0"></h1>
                        </div>
                    </div>
                    <div className="img-fluid" data-src={Image3} width={'100%'} height={'100%'}>
                        <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0"></h1>
                        </div>
                    </div>
                    <div className="img-fluid" data-src={Image4} width={'100%'} height={'100%'}>
                        <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0"></h1>
                        </div>
                    </div>
                    <div className="img-fluid" data-src={Image5} width={'100%'} height={'100%'}>
                        <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0"></h1>
                        </div>
                    </div>
                    <div className="img-fluid" data-src={Image6} width={'100%'} height={'100%'}>
                        <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0"></h1>
                        </div>
                    </div>
                </AutoplaySlider>
            </div>
            {/* Gallery End */}

            {/* Project Describe Start */}
            <div className='grid grid-cols-2 project-counter'>
                <div className='whoweare'>
                    <h6>WHO WE ARE</h6>
                    <h1>At VTA Buildcon,<br />we are transforming the skyline, redefining modern living by creating iconic global and stunning landmarks designed for today’s vibrant community in Pune.</h1>
                </div>
                <div className='grid grid-cols-2 counter'>
                    <div className='project-des'>
                        <div className='project-count'>
                            <CountUp start={0} end={21} delay={0.75} />
                            <h6>+</h6>
                        </div>
                        <div className='project-describe'>Projects</div>
                    </div>
                    <div className='project-des'>
                        <div className='project-count'>
                            <CountUp start={0} end={15} delay={0.75} />
                            <h6>+</h6>
                        </div>
                        <div className='project-describe'>Locations</div>
                    </div>
                </div>
            </div>
            {/* Project Describe End */}

            {/* About Us Start */}
            <div className='grid grid-cols-2 divide-x-0 about-us'>
                <div>
                    <img src={AboutUsHome} width={'100%'} height={'100%'}/>
                </div>
                <div className='about-us-des'>
                    <h1 className='about-us-title'>About VTA Buildcon</h1>
                    <h1 className='about-us-detail'>
                        Founded by Mr. Vinod Adaskar in 2017, VTA Buildcon Constructions has become one of Pune’s most loved and trusted real estate brands, with more than 21 projects to its name.
                        <br /><br />
                        From world-class commercial destinations to iconic residential addresses, creations by VTA Buildcon stand at Pune’s most popular and central locations.
                        <p className='about-us-link'><a href='/about'>Read More &#8594;</a></p>
                    </h1>
                    
                </div>
            </div>
            {/* About Us End */}

            {/* Project Describe Start */}
            <div className='project-feature'>
                <div className='point-title'>
                    <h6>FEATURED PROJECTS</h6>
                </div>
                <div className='point-desc'>
                    <h1>Pune's lifestyle is evolving with a focus on health, flexible workspaces, and vibrant cultural events, blending tradition with modern living.</h1>
                    <p className='about-us-link'><a href='/projects'>View Projects &#8594;</a></p>
                </div><br />
                <div className='project-imgs'>
                {/* <div className='project-imgs grid grid-cols-3'> */}
                    <a href='/projects/completed'>
                        <div className='project-img'>
                            <img src={CompletedBuild} className='image' width={'100%'} height={'100%'}/>
                            <div className='project-img-over'>
                                {/* <h1 className='text'>Residential Projects</h1> */}
                                <h1>Completed Project</h1>
                                {/* <p>Gated community projects at Pune's most upcoming & cosmo Locations</p> */}
                            </div>
                        </div>
                    </a>
                    <a href='/projects/ongoing'>
                        <div className='project-img'>
                            <img src={OngoingBuild} className='image' width={'100%'} height={'100%'}/>
                            <div className='project-img-over'>
                                {/* <h1 className='text'>Residential Projects</h1> */}
                                <h1>Ongoing Projects</h1>
                                {/* <p>Business Addresses with the best office & Retail spaces at central Pune</p> */}
                            </div>
                        </div>
                    </a>
                    <a href='/projects'>
                        <div className='project-img'>
                            <img src={UpcomingBuild} className='image' width={'100%'} height={'100%'} />
                            <div className='project-img-over'>
                                {/* <h1 className='text'>Residential Projects</h1> */}
                                <h1>Upcoming Projects</h1>
                                {/* <p>Business Addresses with the best office & Retail spaces at central Pune</p> */}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            {/* Project Describe End */}

            {/* Footer Start */}
            <div className='footer-class'>
                <div class="grid grid-cols-3 footer-content">
                    <div class="footer-column footer-about">
                        <h3>About Us</h3>
                        <p><i className="fa fa-info me-3" aria-hidden="true"></i>We bring dreams to life with a focus on innovation, quality, and sustainability.</p>
                    </div>
                    <div class="footer-column">
                        <h3>Contact Us</h3>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Shaantaai Corner, Bhondve Bagh Rd, Ravet, Pune, Maharashtra, India</p>
                        <p><i className="fa fa-envelope me-3"></i>vtagroup99@gmail.com</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+91 94223 26777</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+91 97669 57777</p>
                    </div>
                    <div class="footer-column">
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i>Facebook</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i>Whatsapp</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i>Instagram</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    &copy; 2024&nbsp;<a>VTA Groups</a>. All rights reserved. Created By &nbsp;<a>Mad Coders</a>.
                </div>
            </div>
            {/* Footer End */}
        </div>
    )
}

export default Home;
