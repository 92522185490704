import React, { useState } from 'react';
import './css/About.css';
import { HashLoader } from 'react-spinners';

import AboutBanner from './assets/image/img_banner.avif'
import ValidCheck from './assets/image/check.avif'

function Contact() {
    // Form Operations
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [enquiry, setEnquiry] = useState('')
    const [desc, setDesc] = useState('')
    const [status, setStatus] = useState('FAILED')

    const [nameAlert, setNameAlert] = useState('alert-hide')
    const [phoneAlert, setPhoneAlert] = useState('alert-hide')
    const [invalidPhoneAlert, setInvalidPhoneAlert] = useState('alert-hide')
    const [enquiryAlert, setEnquiryAlert] = useState('alert-hide')
    const [descAlert, setDescAlert] = useState('alert-hide')

    const [formData, setFormData] = useState({
        timeStamp: new Date().toLocaleString(),
        fullName: '',
        phoneNumber: '',
        enquiryRegarding: 'Contacted Via Form',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangePhone = (n) => {
        console.log(n.length)
        if (n.length > 10 || n.length < 10) {
            setInvalidPhoneAlert('alert-form')
        } else {
            setInvalidPhoneAlert('alert-hide')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name == '' || phone == '' || desc == '') {
            if (name == '') {
                setNameAlert('alert-form')
            }
            if (phone == '') {
                setPhoneAlert('alert-form')
            } else if (phone.length != 10) {
                setInvalidPhoneAlert('alert-form')
            }
            if (desc == '') {
                setDescAlert('alert-form')
            }
        } else {
            setFormView("bg-light rounded p-4 hide-contact")
            setLoadingView("bg-light rounded loading")
            setThanksView("bg-light rounded p-4 h-100 thanks hide-all")

            const data = new FormData();
            data.append('timeStamp', formData.timeStamp);
            data.append('fullName', formData.fullName);
            data.append('phoneNumber', formData.phoneNumber);
            data.append('enquiryRegarding', formData.enquiryRegarding);
            data.append('description', formData.description);

            const Sheet_Url = "https://script.google.com/macros/s/AKfycbxwYzQbvbVPtR5N3VNzcTz8bIeCGfvhQqmhc4k9rYqpxp-sLfWU-40xTBdDJyvKvlbKCA/exec"

            try {
                const result = await fetch(Sheet_Url, {
                    method: 'POST',
                    body: data,
                    muteHttpExceptions: true,
                });

                if (result) {
                    setFormData({
                        timeStamp: new Date().toLocaleString(),
                        fullName: '',
                        phoneNumber: '',
                        enquiryRegarding: 'Received Through Contact Page',
                        description: ''
                    });
                    setStatus('SUCCESS')
                    setFormView("bg-light rounded p-4 hide-contact")
                    setLoadingView("bg-light rounded p-4 loading hide-loading")
                    setThanksView("bg-light rounded p-4 h-100 thanks get-all")
                }
            } catch (error) {
                console.log(error);
                setStatus('FAILED')
            }
        }
    };

    // Form Response
    const [formView, setFormView] = useState("bg-light rounded p-4")
    const [loadingView, setLoadingView] = useState("bg-light rounded loading hide-loading")
    const [thanksView, setThanksView] = useState("bg-light rounded thanks hide-all")

    return (
        <div>
            {/* Contact Us Banner Start */}
            <div className='about-banner'>
                <img src={AboutBanner} width={'100%'} height={'100%'}/>
                <div className='banner-path'>
                    <h1>Contact Us</h1>
                    <p><a href='/home'>Home</a> &#8594; Contact</p>
                </div>
            </div>
            {/* Contact Us Banner End */}

            {/* Contact Start */}
            <div className='grid grid-cols-2 enquiry-form'>
                <div className='div-1'>
                    <h1 className='enquiry-title'>Want to Know More ?</h1>
                    <div className={formView}>
                        <form onSubmit={handleSubmit}>
                            <div className='form-ele'>
                                <label>Full Name</label>
                                <input type='text' name='fullName' value={formData.fullName} onChange={(e) => {
                                    setName(e.target.value);
                                    setNameAlert('alert-hide');
                                    handleChange(e)
                                }} />
                                <p className={nameAlert}>Please Enter Your Name</p>
                            </div>
                            <div className='form-ele'>
                                <label>Phone Number</label>
                                <input type='number' name='phoneNumber' value={formData.phoneNumber} onChange={(e) => {
                                    setPhoneAlert('alert-hide');
                                    setPhone(e.target.value);
                                    handleChangePhone(e.target.value)
                                    handleChange(e)
                                }} />
                                <p className={phoneAlert}>Please Enter Your Phone Number</p>
                                <p className={invalidPhoneAlert}>Please Enter 10 Digit Valid Phone Number</p>
                            </div>
                            <div className='hide-timestamp'>
                                <label>Enquiry Regarding</label>
                                <input type="text" name="enquiryRegarding" value={formData.enquiryRegarding} onChange={(e) => {
                                    handleChange(e)
                                }} disabled />
                            </div>
                            <div className='form-ele'>
                                <label>Description</label>
                                <textarea type='text' name='description' value={formData.description} onChange={(e) => {
                                    setDescAlert('alert-hide');
                                    setDesc(e.target.value);
                                    handleChange(e)
                                }} rows={4}></textarea>
                                <p className={descAlert}>Describe Your Issue</p>
                            </div>
                            <div className='hide-timestamp'>
                                <label htmlFor="timeStamp">Time Stamp:</label>
                                <input type="Date" name="timeStamp" value={formData.timeStamp} onChange={(e) => {
                                    handleChange(e)
                                }} disabled />
                            </div>
                            <div className='form-ele'>
                                <button type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={loadingView}>
                        <HashLoader color='#007902' />
                        <h5>Submitting Your Response ...</h5>
                    </div>
                    <div className={thanksView}>
                        <h1>Thanks for Response</h1>
                        <img src={ValidCheck} alt='Check Arrow Image' width={'100%'} height={'100%'} />
                        <h5>Our Team will Reach You Soon.</h5>
                    </div>
                </div>
                <div className='div-2'>
                    <h1 className='enquiry-title'>Contact Us</h1>
                    <div className='contact-us-detail'>
                        <i className="fa fa-map-marker-alt me-3"></i>
                        <div>
                            <h1>Address</h1>
                            <p>Shantai Corner, Bhondve Rd., Ravet, Pune - 412101</p>
                        </div>
                    </div>
                    <div className='contact-us-detail'>
                        <i className="fa fa-phone-alt me-3"></i>
                        <div>
                            <h1>Call Us</h1>
                            <p>+123 456 7890</p>
                        </div> 
                    </div>
                    <div className='contact-us-detail'>
                        <i className="fa fa-envelope me-3"></i>
                        <div>
                            <h1>Email Us</h1>
                            <p>info@vtagroups.com</p>
                        </div> 
                    </div>
                    <div className='contact-us-detail-icons'>
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i></a>
                            
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i></a>
                            
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact End */}

            {/* Footer Start */}
            <div className='footer-class'>
                <div class="footer-content grid grid-cols-3">
                    <div class="footer-column footer-about">
                        <h3>About Us</h3>
                        <p><i className="fa fa-info me-3" aria-hidden="true"></i>We bring dreams to life with a focus on innovation, quality, and sustainability.</p>
                    </div>
                    <div class="footer-column">
                        <h3>Contact Us</h3>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Shaantaai Corner, Bhondve Bagh Rd, Ravet, Pune, Maharashtra, India</p>
                        <p><i className="fa fa-envelope me-3"></i>info@vtagroups.com</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+123 456 7890</p>
                    </div>
                    <div class="footer-column">
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i>Facebook</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i>Whatsapp</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i>Instagram</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    &copy; 2024&nbsp;<a>VTA Groups</a>. All rights reserved. Created By &nbsp;<a>Mad Coders</a>.
                </div>
            </div>
            {/* Footer End */}
        </div>
    )
}

export default Contact;
