import React from 'react';
import './css/About.css';

import AboutBanner from './assets/image/img_banner.avif'
import VMImage from './assets/image/vm_image.avif'
import AboutUsImage from './assets/image/about_us_img.avif'
import MissionImage from './assets/image/mission.avif'
import VisionImage from './assets/image/vision.avif'
import DirectorImage1 from './assets/image/director.avif'

function About() {
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner'>
                <img src={AboutBanner} width={'100%'} height={'100%'}/>
                <div className='banner-path'>
                    <h1>About Us</h1>
                    <p><a href='/home'>Home</a> &#8594; About</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* About Us Start */}
            <div className='grid grid-cols-2 divide-x-0 about-us'>
                <div className='aboutUsImageDiv'>
                    <img src={AboutUsImage} className='aboutUsImage' width={'100%'} height={'100%'}/>
                </div>
                <div className='about-us-des'>
                    <h1 className='about-us-title'>About Us</h1>
                    <h1 className='about-us-detail'>
                        In 2004, as Pune’s real estate market began to flourish, a unique opportunity arose—the demand for upgraded residential spaces in the city’s prime locations. Recognizing this trend early on, VTA Buildcon set out to meet the aspirations of the local community.
                        <br /><br />
                        Over the years, VTA Buildcon has become synonymous with innovation and quality, leading the way in enhancing residential spaces across Pune's most sought-after neighborhoods, such as Ravet, Punawale, Aundh, Akurdi, Nigdi, Chikhali. With more than 21 successful projects, VTA Buildcon has earned the trust of Punekars and Pimpri-Chinchwadkars, making it the top choice for modern living.
                    </h1>
                </div>
            </div>
            {/* About Us End */}

            {/* Vision mission Start */}
            <div className='vision-mission'>
                <div className='vision-mission-image'>
                    <img src={VMImage} width={'100%'} height={'100%'}/>
                    <div className='grid grid-cols-2 vision-mission-card'>
                        <div className='vision-card'>
                            <img src={VisionImage} width={'100%'} height={'100%'}/>
                            <h1>Vision</h1>
                            <p>To elevate the profile and standing of VTA Buildcon as a premier concrete business group, while fostering a holistic impact on the real estate industry and other sectors across India.</p>
                        </div>
                        <div className='vision-card'>
                            <img src={MissionImage} width={'100%'} height={'100%'}/>
                            <h1>Mission</h1>
                            <p>To cultivate enduring relationships with clients based on their trust, investments, and emotions. To fully realize their aspirations and bring joy to all customers through our services at VTA Buildcon.</p>
                        </div>
                    </div>
                </div>  
            </div>
            {/* Vision mission End */}

            {/* Board of Directors Start */}
            <div className='board-class'>
                <h2 className='board-class-heading'>Founder</h2>
                <div className='grid grid-cols-2 board-class-cards'>
                    <div className='grid-cols-2 director-card'>
                        <div className='director-image'>
                            <img src={DirectorImage1} width={'100%'} height={'100%'}/>
                        </div>
                        <div className='director-info'>
                            <h6>Founder</h6>
                            <h1>Mr. Vinod Adaskar</h1>
                            <p>With a keen insight into the evolving desires of modern Punekars, Vinod Adaskar has skillfully identified and fulfilled their aspirations for an elevated lifestyle. His innovative approach and dedication have made a significant impact on Pune's and Pimpri-Chinchwad's real estate landscape, setting trends that resonate with the community’s vision for upscale living.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Board of Directors End */}

            {/* Footer Start */}
            <div className='footer-class'>
                <div class="footer-content grid grid-cols-3">
                    <div class="footer-column footer-about">
                        <h3>About Us</h3>
                        <p><i className="fa fa-info me-3" aria-hidden="true"></i>We bring dreams to life with a focus on innovation, quality, and sustainability.</p>
                    </div>
                    <div class="footer-column">
                        <h3>Contact Us</h3>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Shaantaai Corner, Bhondve Bagh Rd, Ravet, Pune, Maharashtra, India</p>
                        <p><i className="fa fa-envelope me-3"></i>info@vtagroups.com</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+123 456 7890</p>
                    </div>
                    <div class="footer-column">
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i>Facebook</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i>Whatsapp</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i>Instagram</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    &copy; 2024&nbsp;<a>VTA Groups</a>. All rights reserved. Created By &nbsp;<a>Mad Coders</a>.
                </div>
            </div>
            {/* Footer End */}
        </div>
    )
}

export default About;
