import React, {useState} from 'react';
import './css/CommercialSite.css';
import { HashLoader } from 'react-spinners';

import AboutBanner from './assets/image/commercial/banners/shantai_corner.avif'
import OverviewImage from './assets/image/building/image2.avif'
import bhk1 from './assets/image/flat/1bhk.avif'
import bhk2 from './assets/image/flat/2bhk.avif'
import ValidCheck from './assets/image/check.avif'

// Amenities
import LiftAmenity from './assets/image/amenities/lift.avif'
import WaterAmenity from './assets/image/amenities/water.avif'
import PowerAmenity from './assets/image/amenities/electricity.avif'
import CommonAmenity from './assets/image/amenities/commonspace.avif'
import SecurityAmenity from './assets/image/amenities/security.avif'
import CCTVAmenity from './assets/image/amenities/cctv.avif'
import FireAmenity from './assets/image/amenities/fire.avif'
import ParkingAmenity from './assets/image/amenities/parking.avif'

function ResShantaiGreen() {
    // Form Operations
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [enquiry, setEnquiry] = useState('')
    const [desc, setDesc] = useState('')
    const [status, setStatus] = useState('FAILED')

    const [nameAlert, setNameAlert] = useState('alert-hide')
    const [phoneAlert, setPhoneAlert] = useState('alert-hide')
    const [invalidPhoneAlert, setInvalidPhoneAlert] = useState('alert-hide')
    const [enquiryAlert, setEnquiryAlert] = useState('alert-hide')
    const [descAlert, setDescAlert] = useState('alert-hide')

    const [formData, setFormData] = useState({
        timeStamp: new Date().toLocaleString(),
        fullName: '',
        phoneNumber: '',
        enquiryRegarding: 'Shantai Green, Ravet',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangePhone = (n) => {
        console.log(n.length)
        if (n.length > 10 || n.length < 10) {
            setInvalidPhoneAlert('alert-form')
        } else {
            setInvalidPhoneAlert('alert-hide')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name == '' || phone == '' || desc == '') {
            if (name == '') {
                setNameAlert('alert-form')
            }
            if (phone == '') {
                setPhoneAlert('alert-form')
            } else if (phone.length != 10) {
                setInvalidPhoneAlert('alert-form')
            }
            if (desc == '') {
                setDescAlert('alert-form')
            }
        } else {
            setFormView("bg-light rounded p-4 hide-contact")
            setLoadingView("bg-light rounded loading")
            setThanksView("bg-light rounded p-4 h-100 thanks hide-all")

            const data = new FormData();
            data.append('timeStamp', formData.timeStamp);
            data.append('fullName', formData.fullName);
            data.append('phoneNumber', formData.phoneNumber);
            data.append('enquiryRegarding', formData.enquiryRegarding);
            data.append('description', formData.description);

            const Sheet_Url = "https://script.google.com/macros/s/AKfycbxwYzQbvbVPtR5N3VNzcTz8bIeCGfvhQqmhc4k9rYqpxp-sLfWU-40xTBdDJyvKvlbKCA/exec"

            try {
                const result = await fetch(Sheet_Url, {
                    method: 'POST',
                    body: data,
                    muteHttpExceptions: true,
                });

                if (result) {
                    setFormData({
                        timeStamp: new Date().toLocaleString(),
                        fullName: '',
                        phoneNumber: '',
                        enquiryRegarding: 'Shantai Green, Ravet',
                        description: ''
                    });
                    setStatus('SUCCESS')
                    setFormView("bg-light rounded p-4 hide-contact")
                    setLoadingView("bg-light rounded p-4 loading hide-loading")
                    setThanksView("bg-light rounded p-4 h-100 thanks get-all")
                }
            } catch (error) {
                console.log(error);
                setStatus('FAILED')
            }
        }
    };

    // Form Response
    const [formView, setFormView] = useState("bg-light rounded p-4")
    const [loadingView, setLoadingView] = useState("bg-light rounded loading hide-loading")
    const [thanksView, setThanksView] = useState("bg-light rounded thanks hide-all")
    
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner building-banner'>
                <img width={'100%'} height={'100%'} src={AboutBanner} />
                <div className='banner-path'>
                    <h1>Shantai Green, Ravet</h1>
                    <p><a href='/home'>Home</a> &#8594; <a href='/projects'>Projects</a> &#8594; <a href='/projects/completed'>Completed Projects</a> &#8594; Shantai Green</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* Sub Navbar Start */}
            <div className="sub-navbar">
                <ul>
                    <li><a href='#overview'>OVERVIEW</a></li>
                    <li><a href='#amenities'>AMENITIES</a></li>
                    <li><a href='#floorplan'>FLOOR PLAN</a></li>
                    <li><a href='#maplocation'>LOCATION</a></li>
                    <li><a href='#available'>AVAILABILITY</a></li>
                    <li><a href='#enquiry'>ENQUIRY</a></li>
                </ul>
            </div>
            {/* Sub Navbar End */}

            {/* Overview Start */}
            <div className='overview-navbar'>
                <h1 className='overview-title' data-wow-delay="0.5s" id='overview'>Overview</h1>
                <div className='grid grid-cols-3 overview-detail'>
                    <div className='overview-image'>
                        <img width={'100%'} height={'100%'} src={OverviewImage} />
                    </div>
                    <div className='overview-para'>
                        <p> &nbsp; Does a well-developed central location, which has become a hub for many service-driven businesses, need anything more? Well, an upgraded and exclusive business destination that does justice to the stature of businesses. Welcome to VTA Buildcon Business Bay at Ravet, a state-of-the-art business address that offers Residential areas with Beautiful Environment.</p>
                    </div>
                    <div className='overview-desc'>
                        <div>
                            <h1>Location</h1>
                            <p>Ravet</p>
                        </div>
                        <div>
                            <h1>Type</h1>
                            <p>Residential + Commercial</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Overview End */}

            {/* Amenities Start */}
            <div className='amenities-navbar' id='amenities'>
                <h3 className='amenities-slogan'>SERVICES TO KEEP YOU SATISFY</h3>
                <h1 className='amenities-title'>Amenities</h1>
                <div className='amenities-desc'>
                    <p>Owning a business brings with it a lot of responsibility and involves juggling many different tasks.Maintaining your business premises is important. While you focus on work, we ensure everything else falls into place. At VTA Buildcon Business Bay, resourceful management services and upkeep complete your business needs.</p>
                </div>
                <div className='amenities-provide'>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={LiftAmenity} />
                        <p>Lifts with Auto<br />Door Operations</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={WaterAmenity} />
                        <p>24/7 Water Supply</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={CCTVAmenity} />
                        <p>CCTV Security</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={ParkingAmenity} />
                        <p>Parking Space</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={PowerAmenity} />
                        <p>Power backup</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={CommonAmenity} />
                        <p>Common Areas and<br />Spaces</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={SecurityAmenity} />
                        <p>Entrance Gate<br />with Security</p>
                    </div>
                    <div className='amenity'>
                        <img width={'100%'} height={'100%'} src={FireAmenity} />
                        <p>Fire Fighting<br />System</p>
                    </div>
                </div>
            </div>
            {/* Amenities End */}

            {/* Floor Plan Start */}
            <div className='floor-navbar' id='floorplan'>
                <h3 className='floor-slogan'>PERFECTING ARCHITECTURE</h3>
                <h1 className='floor-title'>Floor Plan & Availability</h1>
                <div className='floor-desc'>
                    <p>we are committed to delivering the best and most innovative architectural solutions tailored to meet the unique needs of our valued customers. With a focus on precision, quality, and sustainability, we ensure every project is a perfect blend of design excellence and functional integrity, transforming visions into reality.</p>
                </div>
                <div className='floor-imgs'>
                    <div className='floor-plan'>
                        <img width={'100%'} height={'100%'} src={bhk1} />
                        <h1>1 BHK Floor Plan</h1>
                    </div>
                    <div className='floor-plan'>
                        <img width={'100%'} height={'100%'} src={bhk2} />
                        <h1>2 BHK Floor Plan</h1>
                    </div>
                </div>
            </div>
            {/* Floor Plan End */}

            {/* Location Start */}
            <div className='map-location' id='maplocation'>
                <h1 className='location-title'>Location</h1>
                <div className='map-class'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.053553728469!2d73.74261547595894!3d18.661592664849827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b0ab015d2cd7%3A0xa07d494171d6e306!2sShantai%20Green!5e0!3m2!1sen!2sin!4v1733984023585!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            {/* Location End */}

            {/* Availability Start */}
            <div className='available-unit' id='available'>
                <h1 className='available-title'>Available Units</h1>
                <table>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Building</th>
                        <th>Flat No.</th>
                        <th>Configuration</th>
                        <th>Carpet Area</th>
                        <th>Floor Plan</th>
                    </tr>
                    <tr>
                        <td colSpan={6}>-- SOLD OUT --</td>
                    </tr>
                </table>
            </div>
            {/* Availability End */}

            {/* Enquiry Start */}
            <div className='enquiry-form' id='enquiry'>
                <h1 className='enquiry-title'>Want to Know More ?</h1>
                <div className={formView}>
                    <form onSubmit={handleSubmit}>
                        <div className='form-ele'>
                            <label>Full Name</label>
                            <input type='text' name='fullName' value={formData.fullName} onChange={(e) => {
                                setName(e.target.value);
                                setNameAlert('alert-hide');
                                handleChange(e)
                            }} />
                            <p className={nameAlert}>Please Enter Your Name</p>
                        </div>
                        <div className='form-ele'>
                            <label>Phone Number</label>
                            <input type='number' name='phoneNumber' value={formData.phoneNumber} onChange={(e) => {
                                setPhoneAlert('alert-hide');
                                setPhone(e.target.value);
                                handleChangePhone(e.target.value)
                                handleChange(e)
                            }} />
                            <p className={phoneAlert}>Please Enter Your Phone Number</p>
                            <p className={invalidPhoneAlert}>Please Enter 10 Digit Valid Phone Number</p>
                        </div>
                        <div className='hide-timestamp'>
                            <label>Enquiry Regarding</label>
                            <input type="text" name="enquiryRegarding" value={formData.enquiryRegarding} onChange={(e) => {
                                handleChange(e)
                            }} disabled />
                        </div>
                        <div className='form-ele'>
                            <label>Description</label>
                            <textarea type='text' name='description' value={formData.description} onChange={(e) => {
                                setDescAlert('alert-hide');
                                setDesc(e.target.value);
                                handleChange(e)
                            }} rows={4}></textarea>
                            <p className={descAlert}>Describe Your Issue</p>
                        </div>
                        <div className='hide-timestamp'>
                            <label htmlFor="timeStamp">Time Stamp:</label>
                            <input type="Date" name="timeStamp" value={formData.timeStamp} onChange={(e) => {
                                handleChange(e)
                            }} disabled />
                        </div>
                        <div className='form-ele'>
                            <button type='submit'>Submit</button>
                        </div>
                    </form>
                </div>
                <div className={loadingView}>
                    <HashLoader color='#007902' />
                    <h5>Submitting Your Response ...</h5>
                </div>
                <div className={thanksView}>
                    <h1>Thanks for Response</h1>
                    <img width={'100%'} height={'100%'} src={ValidCheck} alt='Check Arrow Image' />
                    <h5>Our Team will Reach You Soon.</h5>
                </div>
            </div>
            {/* Enquiry End */}

            {/* Footer Start */}
            <div className='footer-class'>
                <div class="footer-content grid grid-cols-3">
                    <div class="footer-column">
                        <h3>About Us</h3>
                        <p><i className="fa fa-info me-3" aria-hidden="true"></i>We bring dreams to life with a focus on innovation, quality, and sustainability.</p>
                    </div>
                    <div class="footer-column">
                        <h3>Contact Us</h3>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Shaantaai Corner, Bhondve Bagh Rd, Ravet, Pune, Maharashtra, India</p>
                        <p><i className="fa fa-envelope me-3"></i>info@vtagroups.com</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+123 456 7890</p>
                    </div>
                    <div class="footer-column">
                        <h3>Follow Us</h3>
                        <div class="social-icons">
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-facebook-f me-3"></i>Facebook</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#" target="_blank"><i className="fab fa-whatsapp me-3"></i>Whatsapp</a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="#"><i className="fab fa-instagram me-3"></i>Instagram</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    &copy; 2024&nbsp;<a>VTA Groups</a>. All rights reserved. Created By &nbsp;<a>Mad Coders</a>.
                </div>
            </div>
            {/* Footer End */}
        </div>
    )
}

export default ResShantaiGreen;
