import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
    top: 0;
    position: fixed;
    background: #ffffff;
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5%;
    z-index: 12;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.5);
    width: 100%;
    font-family: 20px;
    @media (max-width: 1100px){
        height: 60px;
        padding: 0 2.5%;
    }
`;

export const NavLogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    height: 60px;
    margin-right: 10px;
        @media (max-width: 1100px){
        height: 30px;
    }
`;

export const CompanyName = styled.span`
    font-size: 175%;
    font-weight: bold;
    color: #000000;
    @media (max-width: 1100px){
        font-size: 125%;
    }
`;

export const NavLink = styled(Link)`
    font-size: 100%;
    color: #000000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: rgb(196, 128, 2);
        font-weight: bold;
    }
    &:hover {
        color: rgb(196, 128, 2);
    }
    @media (max-width: 1100px){
        font-size: 90%;
        
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #000000;
    @media screen and (max-width: 1100px) {
        display: block;
        position: absolute;
        top: 15%;
        right: 0%;
        transform: translate(-100%, 50%);
        font-size: 150%;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    font-family: 250%;

    @media screen and (max-width: 1100px) {
        display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        width: 100%;
        background: rgba(255,255,255,0.9);
        padding: 1rem 0;
        z-index: 10;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;
    /* Third Nav */
    /* justify-content: flex-end;
  width: 100vw; */
    @media screen and (max-width: 1100px) {
        display: none;
    }
`;

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: #808080;
    padding: 10px 22px;
    color: #000000;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    /* Second Nav */
    margin-left: 24px;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #808080;
    }
`;

export const Dropdown = styled.div`
    position: relative;
    display: inline-block;
`;

export const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: #f1f1f1;
        }
    }
`;

export const DropdownHover = styled(Dropdown)`
    &:hover ${DropdownContent} {
        display: block;
    }
`;
