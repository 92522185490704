import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from "./Components/Navbar";
import './index.css'

import Home from './Components/Home'
import About from './Components/About'
import Contact from './Components/Contact'

// Commercial Imports
import ComDrishti from './Components/Drishti'
import ComShantaiCorner from './Components/ShantaiCorner'

// Residential Imports
import Completed from './Components/Completed'
import ShantaiHomes from './Components/ShantaiHomes'
import ShantaiClassic from './Components/ShantaiClassic'
import ShantaiGreen from './Components/ShantaiGreen'
import ShantaiGreen2 from './Components/ShantaiGreen2'
import ShantaiHeights from './Components/ShantaiHeights'
import ShantaiHeritage from './Components/ShantaiHeritage'
import ShantaiLaxmiCorner from './Components/ShantaiLaxmiCorner'
import ElenaHomes from './Components/ElenaHomes'
import ShantaiResidency from './Components/ShantaiResidency'
import ShantaiApartment from './Components/ShantaiApartment'
import Roysir from './Components/Roysir'
import DivineHomes from './Components/DivineHomes'
import Upcoming from './Components/Upcoming'
import Ongoing from './Components/Ongoing'
import DivineBless from './Components/DivineBless'
import DivineVastu from './Components/DivineVastu'
import ShantaiClassic2 from './Components/ShantaiClassic2'
import Projects from './Components/Projects';
import ComIBMDeveloper from './Components/IBMDeveloper';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Actual Routes */}
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Projects/>} />
        <Route path='/contact' element={<Contact />} />

        {/* Commercial Path */}
        <Route path='/projects/ongoing' element={<Ongoing />} />
        <Route path='/projects/ongoing/shantai_green_2' element={<ShantaiGreen2 />} />
        <Route path='/projects/ongoing/divine_vastu' element={<DivineVastu />} />
        <Route path='/projects/ongoing/drishti' element={<ComDrishti />} />
        <Route path='/projects/ongoing/ibm_developer' element={<ComIBMDeveloper />} />

        {/* Completed */}
        <Route path='/projects/completed' element={<Completed />} />
        <Route path='/projects/completed/shantai_corner' element={<ComShantaiCorner />} />
        <Route path='/projects/completed/shantai_homes' element={<ShantaiHomes />} />
        <Route path='/projects/completed/shantai_green' element={<ShantaiGreen />} />
        <Route path='/projects/completed/shantai_classic' element={<ShantaiClassic />} />
        <Route path='/projects/completed/shantai_heights' element={<ShantaiHeights />} />
        <Route path='/projects/completed/shantai_heritage' element={<ShantaiHeritage />} />
        <Route path='/projects/completed/shantai_laxmi_corner' element={<ShantaiLaxmiCorner />} />
        <Route path='/projects/completed/elena_homes' element={<ElenaHomes />} />
        <Route path='/projects/completed/shantai_residency' element={<ShantaiResidency />} />
        <Route path='/projects/completed/shantai_apartment' element={<ShantaiApartment />} />
        <Route path='/projects/completed/roysir' element={<Roysir />} />
        <Route path='/projects/completed/divine_homes' element={<DivineHomes />} />
        <Route path='/projects/completed/divine_bless' element={<DivineBless />} />

        {/* Upcoming */}
        <Route path='/projects/upcoming' element={<Upcoming />} />
        <Route path='/projects/upcoming/shantai_classic_phase_2' element={<ShantaiClassic2 />} />
      </Routes>
    </Router>
  );
}

export default App;
